/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-west-2",
    "aws_cognito_identity_pool_id": "us-west-2:b2532b3c-c7f9-45f4-8c73-6f90392c8f35",
    "aws_cognito_region": "us-west-2",
    "aws_user_pools_id": "us-west-2_GvrpNEjui",
    "aws_user_pools_web_client_id": "3krbolqenhcq50qa71rf4u7cvs",
    "oauth": {},
    "aws_appsync_graphqlEndpoint": "https://nrhafr7ka5fstndvsolqvgrexi.appsync-api.us-west-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-west-2",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-biykw2aiirhlfmsy3hddmwfzou",
    "aws_user_files_s3_bucket": "webtina057ae164ce9b4a09abe920e2b9c0ec6424024-dev",
    "aws_user_files_s3_bucket_region": "us-west-2"
};


export default awsmobile;
